<template>
  <span class="time">{{ time }}</span>
</template>
<script>
export default {
  name: "inputTimer",
  data() {
    return {
      time: null,
      timer: null,
    }
  },
  destroyed() {
    this.clearTimer()
  },
  created() {
    this.setTimer()
  },
  methods: {
    setTimer() {
      let time = 300
      let min = ""
      let sec = ""
      let vm = this

      this.timer = setInterval(function() {
        min = parseInt(time/60);
        sec = time%60
        vm.time = `${(`0${min}`).slice(-2)}:${(`0${sec}`).slice(-2)}`
        time --;
        if (time < 0) {
          vm.clearTimer()
          vm.$emit('expire')
        }
      }, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
    }
  }
}
</script>
